<template>
  <div id="app">
        <div class="header-dark" v-bind:class="{ collapsed: (!(['home'].indexOf($route.name) > -1) && this.window.width < 768) }" id="home">
            <nav class="navbar navbar-light navbar-expand-md navigation-clean-search">
                <div class="container"><a class="navbar-brand" href="/"><img src="./assets/images/af-1.png"><span class="brand-name">Businessconsult24</span></a><button class="navbar-toggler" data-toggle="collapse" data-target="#navcol-1"><span class="sr-only">Toggle navigation</span><span class="navbar-toggler-icon"></span></button>
                    <div class="collapse navbar-collapse" id="navcol-1">
                        <ul class="nav navbar-nav">
                            <li class="nav-item" role="presentation"><router-link class="nav-link" to="/">Home</router-link></li>
                            <!--<li class="nav-item" role="presentation"><router-link class="nav-link" to="/portfolio">Portfolio</router-link></li>-->
                            <li class="nav-item" role="presentation"><router-link class="nav-link" to="/karriere">Karriere</router-link></li>
                            <!--<li class="nav-item" role="presentation"><router-link class="nav-link" to="/news">News</router-link></li>-->
                            <li class="nav-item" role="presentation"><router-link class="nav-link" to="/partner">Partner &amp; Referenzen</router-link></li>
                        </ul>
                        <!--<span class="navbar-text">
                            <router-link class="nav-link" to="/kontakt">Kontakt</router-link>
                        </span>-->
                        <ul class="nav navbar-nav navbar-right">
                            <li class="nav-item" role="presentation"><router-link class="nav-link" to="/kontakt">Kontakt</router-link></li>
                        </ul>
                </div>
        </div>
        </nav>
        <div v-if="!(!(['home'].indexOf($route.name) > -1) && this.window.width < 768)" class="container hero">
            <div class="row align-items-center">
                <div class="col-md-8 offset-md-2">
                    <h1 class="text-center">Wer wir sind</h1>
                    <p class="header-text">Businessconsult24 agiert agil und zuverlässig als Bindeglied zwischen Netzbetreibern und Standorteigentümern. <br><br>
Durch die Identifizierung und Sicherung geeigneter Standorte für Funkstationen, Elektroladestationen oder Flächen zur Nutzung alternativer Energien kombinieren wir jahrzehntelange Erfahrung in der Akquisition mit der Fähigkeit, Vertragsverhandlungen erfolgreich und effizient im Sinne unserer Auftraggeber zu führen.<br><br>
Dabei werden sowohl die Interessen der Eigentümer von Dach- und Freiflächen als auch die Anforderungen unserer Kunden, der Netzbetreiber, berücksichtigt. <br><br>
Alle deutschen Mobilfunknetzbetreiber profitieren bereits von unserem fachlichen Know-how, unserer starken Akquiseleistung und einer engagierten, erfolgsorientierten Projektabwicklung. <br><br>
So hat sich Businessconsult24 zu einem verlässlichen Partner für den Ausbau und Betrieb moderner Kommunikationsnetze entwickelt, die die Digitalisierung erst möglich machen.</p>
                </div>
            </div>
        </div>
        <div class="header-button">
            <a href="#" v-scroll-to="'#body'"><font-awesome-icon class="icon" icon="chevron-down" /></a>
        </div>
        <!--<div class="header-info">
            <div class="container">
                <div class="row">
                    <div class="col col-12 col-sm-6 header-info-box"><font-awesome-icon style="font-size: 32px;" icon="phone" />
                        <div class="header-info-text"><span>Tel +49 2161 6211-380</span><span>Fax: +49 2161 6211-399</span></div>
                    </div>
                    <div class="col col-12 col-sm-6 header-info-box"><font-awesome-icon style="font-size: 32px;" icon="location-arrow" />
                        <div class="header-info-text"><span>Hermannstraße 17</span><span>41061 Mönchengladbach</span></div>
                    </div>
                </div>
            </div>
        </div>-->
    </div>
    <router-view />
    <div class="footer-dark">
        <footer>
            <div class="container">
                <div class="row">
                    <div class="col-sm-6 col-md-3 item">
                        <h3>Businessconsult24</h3>
                        <ul>
                            <li><span>eine Marke der<br></span></li>
                            <li><span>Ingo Demes &amp; Frank Amaliadis GbR<br></span></li>
                            <li><span>Düsseldorfer Landstraße 59<br></span></li>
                            <li><span>47249 Duisburg<br></span></li>
                            <li><span>Deutschland<br></span></li>
                            <li></li>
                            <li><span>Geschäftsführer:<br></span></li>
                            <li><span>Ingo Demes und Frank Amaliadis<br></span></li>
                            <li><span>USt.-ID: DE319769108<br></span></li>
                        </ul>
                    </div>
                    <div class="col-sm-6 col-md-3 item">
                        <h3>Kontakt</h3>
                        <ul>
                            <li><span>Tel +49 (0) 172 72 76 76 7<br></span></li>
                            <li><span>Web: www.businessconsult24.de<br></span></li>
                            <li><span>Mail: info@businessconsult24.de</span></li>
                        </ul>
                    </div>
                    <div class="col-md-6 item text"><iframe width="100%" class="embed-responsive-item" src="//maps.google.com/maps?output=embed&q=Düsseldorfer Landstraße 59, 47249&t=m&hl=de"></iframe></div>
                    <!-- <div class="col item social"><a href="#"><font-awesome-icon :icon="['fab', 'xing']" /></a><a href="#"><font-awesome-icon :icon="['fab', 'linkedin']" /></a><a href="#"><font-awesome-icon :icon="['fab', 'facebook']" /></a><a href="#"><font-awesome-icon :icon="['fab', 'youtube']" /></a></div> -->
                </div>
                <p class="copyright">Businessconsult24 GmbH © 2020 | <router-link class="links" to="/impressum">Impressum</router-link> | <router-link class="links" to="/datenschutz">Datenschutzerklärung</router-link> | <a class="cookie-link" v-on:click.prevent="openCookieContainer">Cookie-Einstellungen</a></p>
            </div>
        </footer>
    </div>
    <div v-if="show" class="cookie-container active">
      <p>Wir verwenden Cookies, um die Leistung der Website zu verbessern, Analysen durchzuführen und Ihnen Inhalte und Werbung zu zeigen, die für Sie relevant sind. Sie können Ihre Einwilligungsentscheidungen jederzeit in Ihren Datenschutzeinstellungen ändern.</p>
      <form v-on:submit.prevent="onSubmit">
          <div class="form-row">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="nc" id="check1" disabled checked v-model="nc">
                <label class="form-check-label" for="check1">
                    Notwendige Cookies
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="ga" id="check2" :checked="ga == true" v-model="ga">
                <!--<input v-else class="form-check-input" type="checkbox" value="ga" id="check2" v-model="ga">-->
                <label class="form-check-label" for="check2">
                    Google-Analytics
                </label>
              </div>
          </div>
          <button type="submit" class="cookie-btn">Speichern und schließen</button>
      </form>
    </div>
    <button v-if="scroll" id="scrollToTopBtn" title="Go to top" v-scroll-to="'#home'"><font-awesome-icon icon="chevron-up" /></button>
  </div>
</template>

<script>
export default {
  name: "app",
  data() {
      return {
        show: true,
        nc: true,
        ga: false,
        scroll: false,
        window: {
            width: 768,
            height: 0
        }
      }
  },
  created() {
      window.addEventListener('scroll', this.handleScroll);
      window.addEventListener('resize', this.handleResize);
  },
  destroyed () {
      window.removeEventListener('scroll', this.handleScroll);
      window.removeEventListener('resize', this.handleResize);
  },
  mounted() {
      if(!this.$cookies.isKey("bcc"))
      {
          this.$cookies.set("bcc", this.ga, "30d")
      } else {
          this.ga = this.$cookies.get("bcc");
          this.show = false;
      }
  },
  components: {
    
  },
  methods: {
    handleResize() {
        this.window.width = window.innerWidth;
        this.window.height = window.innerHeight;
    },
    onSubmit() {
        this.show = false;
        if(this.ga)
        {
            this.$ga.enable();
        } else {
            this.$ga.disable();
        }
        this.$cookies.set("bcc", this.ga, "30d")
    },
    openCookieContainer() {
        this.show = true;
    },
    handleScroll (event) {
      if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
          this.scroll = true;
      } else {
          this.scroll = false;
      }
    }
  }
};
</script>

<style>

@import '~bootstrap/dist/css/bootstrap.css';
@import './assets/style/styles.css';
@import './assets/style/Footer-Dark.css';
@import './assets/style/Header-Dark.css';
</style>
